// istanbul ignore file
import { translate, translateFormatter } from '@manomano-internal/i18n/esm/placeholders';
export const arrows = translate({
  "titleLeft": "Precedente",
  "titleRight": "Seguente"
});
export const price = translate({
  "vatExclLabel": "IVA ESCL",
  "vatInclLabel": "IVA INCL"
});
export const rating = {
  ariaLabel: (value: number) => translateFormatter<{
    value: number;
  }>("{value} su 5", undefined, undefined, "it").format({
    value
  }) as string,
  reviewsLabel: (value: number) => translateFormatter<{
    value: number;
  }>("su {value, plural, =1 {# opinione} other {# opinioni}}", undefined, undefined, "it").format({
    value
  }) as string
};
export const pagination = translate({
  "previousPageAriaLabel": "Pagina precedente",
  "nextPageAriaLabel": "Pagina successiva"
});
export const counter = translate({
  "decrementAriaLabel": "Diminuire",
  "incrementAriaLabel": "Aumentare"
});
export const inputPhone = {
  countriesLabels: translate({
    "BE": "Belgio",
    "DE": "Germania",
    "ES": "Spagna",
    "FR": "Francia",
    "GB": "Regno Unito",
    "IT": "Italia",
    "LU": "Lussemburgo",
    "CH": "Svizzera"
  })
};